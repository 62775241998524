<template>
  <div>
    <div
      class="card mb-lg-0"
      v-if="blogGet.comment_permission == 1 && $store.state.isLogin"
    >
      <div class="card-header">
        <h3 class="card-title">Yorum Yazın</h3>
      </div>
      <div class="card-body">
        <h4>{{ $store.state.fullname }}</h4>
        <div class="form-group">
          <textarea
            class="form-control"
            name="comment"
            id="comment"
            rows="6"
            placeholder="Yorumunuzu Yazın"
          ></textarea>
        </div>
        <button id="sendButton" @click="sendComment" class="btn btn-primary">
          Yorumu Gönder
        </button>
      </div>
    </div>
    <div class="card mb-lg-0 p-4 text-center" v-else>
      YORUM YAPMAK İÇİN <router-link to="/login">GİRİŞ YAPIN</router-link>
    </div>
  </div>
</template>
<script>
export default {
  props: ["blogGet"],
  setup() {},
  methods: {
    sendComment() {
      let comment = document.querySelector("textarea[name=comment]").value;
      if (comment == null || comment == "") {
        this.$vToastify.warning("Yorum alanı boş bırakılamaz", "Uyarı!");
      } else {
        document.getElementById("sendButton").disabled = true;
        document.getElementById("sendButton").innerHTML =
          "Yorumunuz gönderiliyor..";
        let commentSave = {
          id: this.blogGet.id,
          comment: comment,
          parent_id: 0,
        };
        this.$store.dispatch("sendBlogComment", commentSave).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxNCYS") {
              this.$vToastify.warning(
                "Kendi profiline yorum yapamazsın!",
                "Uyarı!"
              );
            }
          }
          if (value.type == "success") {
            if (value.message == "SCCx001") {
              this.$vToastify.success(
                "Yorum başarılı bir şekilde eklendi",
                "Başarılı!"
              );
            }
          }
          document.querySelector("textarea[name=comment]").value = "";
          document.querySelector("textarea[name=comment]").disabled = true;
          document.getElementById("sendButton").disabled = true;
          document.getElementById("sendButton").innerHTML = "Gönder";
        });
      }
    },
  },
};
</script>