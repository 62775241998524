<template>
  <div>
    <div class="card" v-if="blogGet.comment_permission == 1">
      <div class="card-header">
        <h3 class="card-title">Yorumlar</h3>
      </div>
      <div class="card-body p-0">
        <div
          class="media mt-0 p-5"
          v-for="(item, i) in commentList"
          :key="'comment' + i"
        >
          <div class="d-flex me-3">
            <a href="#"
              ><lazy-image
                class="media-object brround"
                alt="64x64"
                :src="'https://storage.terapivitrini.com/' + item.profile_image_url"
              />
            </a>
          </div>
          <div class="media-body">
            <h5 class="mt-0 mb-1 font-weight-semibold">
              {{ item.fullname }}
            </h5>
            <small class="text-muted"
              ><i class="fa fa-calendar"></i>
              {{ item.created_at.split("_")[0] }}
              <i class="ms-3 fa fa-clock-o"></i>
              {{ item.created_at.split("_")[1] }}
            </small>
            <p class="font-13 mb-2 mt-2">
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <CommentWrite v-if="blogGet.comment_permission == 1" :blogGet="blogGet" />
  </div>
</template>
<script>
import CommentWrite from "./CommentWrite.vue";
export default {
  components: { CommentWrite },
  props: ["blogGet"],
  created() {},
  data() {
    return {
      commentList: [],
    };
  },
  mounted() {
    let blogComments = {
      authUserId: this.$store.state.isLogin ? this.$store.state.user_id : 0,
      id: this.blogGet.id,
      begin: 0,
      perpage: 20,
    };
    this.commentList = [];
    this.$store.dispatch("blogComments", blogComments).then((value) => {
      this.commentList = value.list;
    });
  },
};
</script>
