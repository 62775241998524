<template>
  <div class="card">
    <div class="card-header">
      <h4>Sıkça Sorulan Sorular</h4>
    </div>
    <div class="card-body" >
      <div class="panel-group1" id="accordion2" itemscope itemtype="https://schema.org/FAQPage">
        <div v-for="item,i in list" :key="i" class="panel panel-default mb-4 border p-0" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
          <div class="panel-heading1">
            <h2 class="panel-title1">
              <a
              
                class="accordion-toggle collapsed"
                data-bs-toggle="collapse"
                :data-parent="'#accordions'+i"
                :href="'#accordions'+i"
                aria-expanded="false"
                ><span itemprop="name">{{item.title}}</span></a
              >
            </h2>
          </div>
          <div
          itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer"
            :id="'accordions'+i"
            class="panel-collapse collapse active"
            role="tabpanel"
            aria-expanded="false"
          >
            <div itemprop="text" class="panel-body bg-white" v-html="item.content">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list"],
};
</script>