<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="Blog Detay" :array="[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: true, url: '/blog', text: 'Blog' },
        { islink: false, url: '', text: blogGet.title },
      ]" />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12">
            <div class="card mb-4" v-if="isLoad">
              <div class="card-body">
                <div class="skeleton w-100 p-0 mb-4">
                  <img style="opacity:0;" src="https://storage.terapivitrini.com/storage/files/1706179768_loading_image.jpg">
                </div>
                <div class="skeleton w-70 item7-card-desc d-flex mb-2 mt-3" style="height:42px;"></div>
                <div v-for="item in 2" :key="'loadContent' + item">
                  <h1 class="skeleton page-title w-100"></h1>
                  <div class="skeleton w-50 mt-2 p-2"></div>
                  <div class="skeleton w-20 mt-2 p-2"></div>
                  <div class="skeleton w-60 mt-2 p-2"></div>
                  <div class="skeleton w-70 mt-2 p-2"></div>
                  <div class="skeleton w-10 mt-2 p-2"></div>
                  <div class="skeleton w-80 mt-2 p-2"></div>
                  <div class="skeleton w-50 mt-2 p-2"></div>
                  <div class="skeleton w-70 mt-2 p-2"></div>
                  <div class="skeleton w-10 mt-2 p-2"></div>
                </div>
              </div>
            </div>
            <div v-if="!isLoad" class="card">
              <div class="card-body">
                
                <div class="item7-card-img">
                  <lazy-image rel="preload" fetchpriority="high" :src="'https://storage.terapivitrini.com/' + blogGet.thumbnail
                    " :alt="blogGet.title" class="w-100" style="width: 100%; height:100%;" />
                </div>
                <div class="item7-card-desc d-flex mb-2 mt-3" style="height: 42px;">
                  <a><i class="fa fa-calendar-o text-muted me-2"></i>{{ blogGet.created_at }}</a>

                  <router-link v-if="blogGet.type == 'EXPERT'" :to="'/terapist/' + blogGet.shortlink"><i
                      class="fa fa-user text-muted me-2"></i>{{ blogGet.fullname }}</router-link>
                  <p style="float: right" v-if="blogGet.type == 'USER'">
                    <i class="fa fa-user text-muted me-2"></i>{{ blogGet.fullname }}
                  </p>
                  <div class="ms-auto">
                    <a><i class="fa fa-comment-o text-muted me-2"></i>{{ blogGet.commentcount }} Yorum</a>
                  </div>
                </div>
                <h1 class="page-title" style="line-height: normal;height: 45px;width: 100%;display: flex;align-content: center;align-items: center;">{{ blogGet.title }}</h1>
                <div v-html="blogGet.content"></div>
              </div>
            </div>
            <template v-if="!isLoad && faqList.length > 0">
              <BlogFaq :list="faqList" />
            </template>
            <template v-if="!isLoad && blogGet != null">
              <Comments :blogGet="blogGet" />
            </template>
          </div>

          <!--Rightside Content-->
          <div class="col-xl-4 col-lg-4 col-md-12">
            <Item1 :item="ads" />
            <SurveyInfo :item="survey" />
            <div class="card" v-if="users.length != 0">
              <div class="card-header mb-3" >
                <h3 class="card-title">İlgili Profiller</h3>
              </div>
              <ProfileItem :list="users" />
            </div>
            <template v-if="isCategoryLoad">
              <div class="card" style="list-style: none">
                <li v-for="(item, i) in 15" :key="'cateogry' + i" class="skeleton p-5 mt-2"></li>
              </div>
            </template>


            <template v-if="!isCategoryLoad">
              <SideBar :categoryList="categoryList" />
            </template>


          </div>
          <!--/Rightside Content-->
        </div>
      </div>
    </section>
    <!--/Add listing-->
  </div>
</template>
<script>
import ProfileItem from "../SearchPages/Components/SlidableProfileItem.vue";
import Comments from "./Components/Comments.vue";
import SideBar from "./Components/SideBar.vue";
import BlogFaq from "./Components/BlogFaq.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import SurveyInfo from "../../components/SurveyInfo.vue";
import Item1 from "../../components/Ads/Item1.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
        {
          name: "author",
          content: this.author,
        },
        {
          name: "keywords",
          content: this.keywords,
        },
        { property: "og:title", content: this.title },
        { property: "og:description", content: this.description },
        { property: "og:image", content: this.image },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("categoryList").then((value) => {
      this.categoryList = value;
      this.isCategoryLoad = false;
    });
    let blogInfo = {
      short_name: this.$route.params.shortname,
    };
    this.$store.dispatch("blogGet", blogInfo).then((value) => {
      this.ads = value.ads;
      this.survey = value.surveyList;
      this.users = value.users;
      this.blogGet = value.list[0];
      this.faqList = value.faqList;
      this.title = value.list[0].title + " - Terapi Vitrini";
      this.keywords =
        value.list[0].title + " - " + value.list[0].tags.toString();
      this.author = value.list[0].fullname;
      if (value.list[0].seo_description == "" || value.list[0].seo_description == null) {
        this.description =
          value.list[0].title +
          " - Zihin ruh beden sağlığınız ve yaşadığınız çeşitli sorunlar için ipuçları ve bilgilere ulaşmak için tıklayın.";
      } else {
        this.description =
          value.list[0].title + " - " + value.list[0].seo_description;
      }
      this.isLoad = false;
      let _this = this;
      $(document).ready(function () {
        _this.image =
          "https://storage.terapivitrini.com/" + _this.blogGet.thumbnail;
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          _this.author,
          _this.keywords,
          _this.image,
          content,
        ]);
      });
    });
  },
  data() {
    return {
      ads: [],
      blogGet: [],
      categoryList: [],
      isCategoryLoad: true,
      isLoad: true,
      users: [],
      faqList: [],
      title: "",
      description: "",
      author: "",
      keywords: "",
      image: "",
      survey: []
    };
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
  },
  components: { ProfileItem, Comments, SideBar, Breadcrumb, Item1, BlogFaq, SurveyInfo },
  mounted() {
    this.backToTop();
  },
};
</script>